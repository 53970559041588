import { graphql, useStaticQuery } from "gatsby"
import React from "react"
// import Img from "gatsby-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const Logo = () => {
  const { logoImage } = useStaticQuery(graphql`
    query {
      logoImage: file(name: { eq: "logo" }) {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: NONE)
        }
      }
    }
  `)
  const logoSrc = getImage(logoImage)
  // console.log("logoSrc:", logoSrc)

  return (
    <GatsbyImage
      image={logoSrc}
      alt="Logo"
      backgroundColor="white"
      style={{ marginBottom: 0 }}
    />
  )
}
